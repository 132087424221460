<template>
  <section id="monitoring-area">
    <div class="vx-row">
      <div class="vx-col w-full md:w-2/3">
        <vue-apex-charts v-if="chart" class="dashboard-chart" type="bar" height="100" :options="chart.options" :series="chart.series" />
        <vx-card title="Latest Captures" class="dashboard-main-table">
          <template slot="actions">
            <p class="leading-none text-right"
              ><span class="font-semibold text-warning text-lg block">{{ totalCaptureIn30Days }}</span
              ><span class="font-semibold font-default text-xs"> Captures taken</span><small class="block text-xs mt-1">(30 Days)</small></p
            >
          </template>
          <vx-data-list
            class="vx-data-list-captures"
            ref="vxDataList"
            :hoverFlat="true"
            :data="list"
            :columns="columns"
            :search="false"
            @filter="filter"
            :config="{
              disablePager: true,
              selectable: false
            }"
          />
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/3">
        <SubscriptionQuote class="md:mt-0 mt-base mb-base" />
        <vx-card class="mb-base" title="active">
          <template slot="title">
            <h6 class="font-semibold">Detect changes made to web pages</h6>
          </template>
          <div class="text-sm">
            <p class="mb-5">
              Don't miss an update, market signal, important news, or even small adjustments.
            </p>
            <p>
              Create an automation for each page you want to monitor. As soon as a visual change is detected, you receive a notification and you can
              easily see what has changed.
            </p>
          </div>
        </vx-card>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CaptureThumbnail from '@/views/captures/components/CaptureThumbnail';
import VueApexCharts from 'vue-apexcharts';
import SubscriptionQuote from '@/components/SubscriptionQuote';
import Paging from '@/entity/Paging';

export default {
  components: {
    VueApexCharts,
    CaptureThumbnail,
    SubscriptionQuote
  },
  data() {
    return {
      chart: null,
      columns: [
        {
          component: CaptureThumbnail
        }
      ]
    };
  },
  computed: {
    ...mapState('captures', ['list', 'summary']),
    chartData() {
      const { $moment, summary } = this;
      let dayCount = 30;
      const out = new Array();
      const moment = $moment().subtract(30, 'days');
      let count = 0;
      while (count < dayCount) {
        const day = moment.add(1, 'day');
        const data = (summary.items || []).find(item => item.date === day.format('YYYY-MM-DD'));
        out.push({
          x: day.utc().format(),
          y: data ? data.count : 0 //Math.floor(Math.random() * 19) + 0
        });
        count++;
      }
      return out;
    },
    totalCaptureIn30Days() {
      const { summary } = this;
      if (!summary.items || !summary.length) return 0;
      return (summary.items || []).map(item => item.count).reduce((a, b) => Number(a || 0) + Number(b || 0));
    }
  },
  methods: {
    async fetchCaptures({ paging } = {}) {
      await this.$store.dispatch('captures/list', { paging: paging || new Paging() });
    },
    filter(paging) {
      this.fetchCaptures({ paging });
    },
    async fetchChartData() {
      const { $moment, $store } = this;
      return await $store.dispatch('captures/summary', {
        from: $moment()
          .subtract(29, 'days')
          .format('YYYY-MM-DD'),
        to: $moment().format('YYYY-MM-DD')
      });
    },
    initChart() {
      const { $router, chartData } = this;
      this.chart = {
        series: [
          {
            name: 'Captures',
            type: 'column',
            data: chartData
          }
        ],
        options: {
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0
            }
          },
          chart: {
            height: 100,
            type: 'line',
            stacked: true,
            sparkline: { enabled: true },
            toolbar: { show: false },
            animations: {
              enabled: false
            },
            events: {
              click: (event, chartContext, config) => {
                const xDataSeries = chartContext.data.twoDSeriesX;
                const datetime = xDataSeries[config.dataPointIndex];
                $router.push({
                  name: 'captures',
                  query: {
                    datecaptured: this.$moment(datetime).format('DD-MM-YYYY')
                  }
                });
              }
            }
          },
          colors: ['#f4ae00'],
          plotOptions: {
            bar: {
              columnWidth: '85%'
              // barHeight: '10%'
            }
          },
          tooltip: {
            enabled: true,
            intersect: true,
            followCursor: true,
            y: {
              formatter: value => value
            }
          },
          xaxis: {
            type: 'datetime'
          }
        }
      };
    }
  },
  async created() {
    await Promise.all([this.fetchCaptures({ paging: { page: 1, size: 10 } }), this.fetchChartData()]);
    this.initChart();
  }
};
</script>
